import {IApi} from "./api";
import {Messenger} from "./messenger";

export enum State {
  init,
  error,
  ok,
}

const MIN_PASSWORD_LENGTH = 6;

export const createPasswordResetStore = (api: IApi, messenger: Messenger) => {
  return {
    state: State.init,
    errorMessage: '',
    async resetPassword(password: string, token: string) {
      const body = {
        password,
        token
      };
      if (password.length < MIN_PASSWORD_LENGTH) {
        messenger.showError(
          `Hasło za krótkie. Hasło musi mieć co najmniej ${MIN_PASSWORD_LENGTH} znaków.`
        );
        return;
      }
      if (!/\d/.test(password)){
        messenger.showError(
          `Hasło musi zawierać co najmniej jedną cyfrę.`
        );
        return;
      }
      const {code, payload} = await api.post('reset', null, body);
      if (code === 200 && payload.ok) {
        this.state = State.ok;
        return;
      }
      else if (code === 410)
        this.errorMessage = 'Invalid token, or token timed out.';
      else
        this.errorMessage = 'Unexpected error.';
      this.state = State.error;
    },
    resetFlow() {
      this.state = State.init;
      this.errorMessage = '';
    }
  }
};

export type TPasswordResetStore = ReturnType<typeof createPasswordResetStore>;