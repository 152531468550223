import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {useStore} from "../store/setup";
import Panel from "./Panel";
import Login from "./Login";
import {observer} from "mobx-react";
import {Route, Switch} from "react-router-dom";
import PasswordReset from "./PasswordReset";
import PasswordResetRequest from "./PasswordResetRequest";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
}

const Main = observer((props: IProps) => {
  const {classes} = props;
  const store = useStore();

  if (store.sessionStore.isAuthenticated)
    return (
      <Panel/>
    );
  return (
    <Switch>
      <Route path="/password-reset" component={PasswordResetRequest} />
      <Route path="/reset">
        <PasswordReset/>
      </Route>
      <Route path="/invitation">
        <PasswordReset invitation={true}/>
      </Route>
      <Route path="/" component={Login}/>
    </Switch>
  );
});

export default withStyles(styles)(Main)