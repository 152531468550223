import * as React from "react";
import {Toolbar, WithStyles} from "@material-ui/core";
import {createStyles, withStyles} from "@material-ui/styles";
import {Theme} from "../theme";
import Typography from "./Typography";
import logo from "../anzonia-logomark.svg"

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px auto 0',
    [theme.breakpoints.up('md')]: {
      margin: '204px auto 0',
    },
    width: 296,
    padding: 16,
    paddingTop: 32,
    paddingBottom: 24,
    borderRadius: 24,
    backgroundColor: theme.palette.raymond,
    boxShadow: '0 16px 32px 0 rgba(15, 15, 15, 0.12);',
  },
  logo: {
    height: 62,
    marginBottom: 7,
  },
  logoImg: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  header: {},
  description: {
    marginTop: 16,
    textAlign: 'left',
  },
  children: {
    marginTop: 0,
    '$description + &, $header + &': {
      marginTop: 24,
    }
  },
});

export interface IBaseFormProps {
  header?: string,
  description?: string,
  children?: any
}

interface IProps extends IBaseFormProps, WithStyles<typeof styles> {

}

class BaseForm extends React.Component<IProps> {
  render() {
    const {header, description, children, classes} = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.logo}>
          <img className={classes.logoImg} src={logo} alt=""/>
        </div>
        {header && (
          <Typography variant="h3Headline">
            {header}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body3"
            classes={{
              root: classes.description
            }}
          >
            {description}
          </Typography>
        )}
        {children && (
          <div className={classes.children}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BaseForm)