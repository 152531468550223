import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import BaseForm from "./BaseForm";
import Button from "./Button";
import {useState} from "react";
import Typography from "./Typography";
import TextField from "./TextField";

const styles = (theme: Theme) => createStyles({
  passwordField: {
  },
  button: {
    marginTop: 36
  },
  emailLabel: {
    marginTop: 34,
    marginBottom: 4
  },
  passwordLabel: {
    marginTop: 12,
    marginBottom: 4
  },
  resetButton: {
    marginTop: 24,
    textAlign: 'center',
    color: theme.palette.impala500,
    cursor: 'pointer'
  }
});

interface IProps extends WithStyles<typeof styles> {
  onLoginClick?: (email: string, password: string) => void,
  onResetPassword?: () => void
}

function LoginForm(props: IProps) {
  const {classes, onLoginClick, onResetPassword} = props;
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLoginClick = (e) => {
    if (onLoginClick)
      onLoginClick(login, password);
    e.preventDefault();
  };

  const handleResetClick = () => {
    if (onResetPassword)
      onResetPassword();
  };

  return (
    <BaseForm>
      <form>
        <Typography
          classes={{
            root: classes.emailLabel
          }}
          variant="label1"
        >
          E-mail:
        </Typography>
        <TextField
          fullWidth
          onChange={handleLoginChange}
        />
        <Typography
          classes={{
            root: classes.passwordLabel
          }}
          variant="label1"
        >
          Hasło:
        </Typography>
        <TextField
          classes={{
            root: classes.passwordField
          }}
          fullWidth
          type="password"
          onChange={handlePasswordChange}
        />
        <Button
          fullWidth
          type="submit"
          classes={{
            root: classes.button
          }}
          onClick={handleLoginClick}
        >
          Zaloguj się
        </Button>
        <Typography
          classes={{
            root: classes.resetButton
          }}
          variant="button3"
          onClick={handleResetClick}
        >
          Resetowanie hasła
        </Typography>
      </form>
    </BaseForm>
  );
}

export default withStyles(styles)(LoginForm)