import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import classNames from "classnames";

const styles = (theme: Theme) => createStyles({
  root: {

  },
  inputRoot: {
    "&$focused $notchedOutline": {
      borderWidth: 1
    }
  },
  outlinedInput: {
    padding: '0 12px',
    '$inputMultiline &': {
      padding: '7px 12px',
    },
    height: 40,
  },
  focused: {},
  disabled: {},
  notchedOutline: {},
  inputMultiline: {
    padding: 0,
  },
});

interface IProps extends WithStyles<typeof styles> {
  value?: string,
  label?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  type?: string,
  multiline?: boolean,
  inputRef?: React.Ref<any>,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>,
}

function TextField(props: IProps) {
  const {classes, value, label, disabled, fullWidth, type, multiline,
    inputRef, onChange, onKeyDown} = props;


  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      fullWidth={fullWidth}
      classes={{
        root: classNames(
          classes.root,
        ),
      }}
    >
      {/*<InputLabel>{label}</InputLabel>*/}
      <OutlinedInput
        classes={{
          root: classes.inputRoot,
          input: classes.outlinedInput,
          focused: classes.focused,
          disabled: classes.disabled,
          notchedOutline: classes.notchedOutline,
          multiline: classes.inputMultiline
        }}
        inputRef={inputRef}
        value={value}
        type={type}
        multiline={multiline}
        onChange={onChange}
        onKeyDown={onKeyDown}
        label={label}
      />
    </FormControl>
  );
}

export default withStyles(styles)(TextField)