import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {ClickAwayListener, PopoverOrigin, WithStyles} from "@material-ui/core";
import {Menu, MenuItem} from "./Menu";
// @ts-ignore (no declarations for lodash)
import _ from 'lodash';
import {useState} from "react";

const styles = (theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    display: 'inline-flex'
  },
  paper: {},
  list: {}
});

interface IProps extends WithStyles<typeof styles> {
  menuItems: any[],
  children: any,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  onSelect?: (index: number)=>void,
}

function MenuOpener(props: IProps) {
  const {classes, menuItems, children, anchorOrigin, transformOrigin, onSelect} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ uid ] = useState(() => _.uniqueId('menu-'));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (index: number) => {
    if (onSelect)
      onSelect(index);
    setAnchorEl(null);
  };

  return (
    <>
      <div
        onClick={handleClick}
        aria-controls={uid}
        aria-haspopup="true"
        className={classes.root}
      >
        {children}
      </div>
      <Menu
        classes={{
          paper: classes.paper,
          list: classes.list
        }}
        id={uid}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableAutoFocusItem
      >
        {menuItems.map((item, index)=>(
          <MenuItem
            key={index}
            onClick={()=>handleItemClick(index)}
          >
            {item}
          </MenuItem>))}
      </Menu>
    </>
  );
}

MenuOpener.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  } as PopoverOrigin,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  } as PopoverOrigin
};

export default withStyles(styles)(MenuOpener)