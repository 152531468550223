export interface ISubscriber {
  onError: (text: string, duration?: number)=>void,
  onWarning: (text: string, duration?: number)=>void,
  onInformation: (text: string, duration?: number)=>void,
  onSuccess: (text: string, duration?: number)=>void,
  onHideAll: ()=>void
}

export class Messenger {

  subscribers = new Set<ISubscriber>();

  addSubscriber(subscriber: ISubscriber) {
    this.subscribers.add(subscriber);
  }

  removeSubscriber(subscriber: ISubscriber) {
    this.subscribers.delete(subscriber);
  }

  showError(text: string, duration?: number) {
    for (let s of this.subscribers)
      s.onError(text, duration);
  }

  showWarning(text: string, duration?: number) {
    for (let s of this.subscribers)
      s.onWarning(text, duration);
  }

  showInformation(text: string, duration?: number) {
    for (let s of this.subscribers)
      s.onInformation(text, duration);
  }

  showSuccess(text: string, duration?: number) {
    for (let s of this.subscribers)
      s.onSuccess(text, duration);
  }

  hideAll() {
    for (let s of this.subscribers)
      s.onHideAll();
  }
}

export class DebugMessenger extends Messenger {
  subscribers = new Set<ISubscriber>([
    {
      onError: (text: string, duration?: number) => {
        console.log('messenger error: ' + text);
      },
      onWarning: (text: string, duration?: number) => {
        console.log('messenger warning: ' + text);
      },
      onInformation: (text: string, duration?: number) => {
        console.log('messenger information: ' + text);
      },
      onSuccess: (text: string, duration?: number) => {
        console.log('messenger success: ' + text);
      },
      onHideAll: () => {

      },
    }
  ])
}