import { translate } from 'typed-intl';

export default translate( {
  errorEmptyPayload: 'Puste zapytanie.',
  errorApiErrorWithDescription: 'Błąd api. Opis: ',
  errorApiUnknown: 'Nieznany błąd api.',
  errorNoAccess: 'Brak dostępu.',
  errorPageNotFound: 'Strona nie istnieje.',
  errorFetchUnexpected: 'Nieoczekiwany błąd pobierania.',
  errorUnexpected: 'Nieoczekiwany błąd.',
  loginErrorWrongPassword: 'Niepoprawne hasło.',

  fileUploadInfo: 'Wysyłanie...',
  fileUploadSuccess: 'Plik wysłany.',
  fileUploadErrorServerSide: 'Błąd podczas wysyłania pliku: błąd po stronie serwera.',
  fileUploadErrorInvalidFile: 'Błąd podczas wysyłania pliku: nieprawdidłowy plik.',
  fileUploadErrorGeneral: 'Błąd podczas wysyłania pliku.',

  fileFetchingErrorGeneral: 'Błąd podczas pobierania pliku.',
  fileFetchingErrorWrongStatus: 'Błąd podczas pobierania pliku: nieprawidłowy status.',
  fileFetchingInfo: 'Pobieranie pliku...',
  fileFetchingErrorBlob: 'Błąd podczas przygotowywania pliku do wysłania.',
  fileFetchingNoAccess: 'Błąd podczas pobierania pliku. Brak dostępu.',

  passwordResetHeader: 'Nie pamiętam hasła.',
  passwordResetDescription: 'Wpisz adres e-mail konta, do którego chcesz odzyskać dostęp.',
  passwordResetButton: 'Zresetuj hasło'
}).partiallySupporting('en', {
  errorEmptyPayload: 'Empty payload.',
  errorApiErrorWithDescription: 'Api error. Description: ',
  errorApiUnknown: 'Unknown api error.',
  errorNoAccess: 'No access.',
  errorPageNotFound: 'Page does not exist.',
  errorFetchUnexpected: 'Unexpected fetch error.',
  errorUnexpected: 'Unexpected error.',

  loginErrorWrongPassword: 'Wrong password.',

  fileUploadInfo: 'Uploading...',
  fileUploadSuccess: 'File uploaded.',
  fileUploadErrorServerSide: 'Error while uploading file: serverside error.',
  fileUploadErrorInvalidFile: 'Error while uploading file: Provided file is invalid.',
  fileUploadErrorGeneral: 'Error while uploading file.',

  fileFetchingErrorGeneral: 'Error while fetching.',
  fileFetchingErrorWrongStatus: 'Error while fetching, wrong status.',
  fileFetchingInfo: 'Downloading...',
  fileFetchingErrorBlob: 'Error while creating blob.',
  fileFetchingNoAccess: 'Error while fetching. No access.',
})