import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {useStore} from "../store/setup";
import {useLocalStore} from "mobx-react-lite";
import {createPasswordResetStore, State} from "../store/passwordResetStore";
import PasswordResetForm from "../components/PasswordResetForm";
import {useHistory, useLocation} from "react-router-dom";
import SummaryForm from "../components/SummaryForm";
import {observer} from "mobx-react";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  invitation?: boolean
}

const PasswordReset = observer((props: IProps) => {
  const {classes, invitation} = props;
  const store = useStore();
  const state = useLocalStore(()=>({
    resetStore: createPasswordResetStore(store.api, store.messenger),
  }));
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token') || '';

  const handleGoToMainPage = () => {
    history.push(`/`);
  };

  const handleReset = (password: string) => {
    state.resetStore.resetPassword(password, token);
  };

  if (state.resetStore.state === State.init)
    return (
      <PasswordResetForm
        description={
          invitation ?
            'Utwórz hasło do swojego nowego konta.' :
            'Wpisz nowe hasło.'
        }
        buttonText={invitation ? 'Utwórz' : 'Reset'}
        messenger={store.messenger}
        onReset={handleReset}
      />
    );

  if (state.resetStore.state === State.error)
    return (
      <SummaryForm
        description={
          invitation ?
            'Zakładanie konta nie powiodło się.' :
            'Zmiana hasła nie powiodła się.'
        }
        buttonName={'Powrót do strony głównej'}
        onGoToMainPage={handleGoToMainPage}
      />
    );

  if (state.resetStore.state === State.ok)
    return (
      <SummaryForm
        description={
          invitation ?
            'Twoje konto zostało założone.' :
            'Hasło zostało zmienione.'
        }
        buttonName={'Przejdź do ekranu logowania'}
        onGoToMainPage={handleGoToMainPage}
      />
    );

  return (
    null
  );
});

export default withStyles(styles)(PasswordReset)