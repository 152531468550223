import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import Card from "./Card";
import {IFurnace} from "../models/models";
import LineChartWithCalendar from "./LineChartWithCalendar";
import {useState} from "react";
import Typography from "./Typography";
import DropIcon from "./DropIcon";
import MenuOpener from "./MenuOpener";
import {LineChartMode} from "./LineChart";

const styles = (theme: Theme) => createStyles({
  cardRoot: {
    width: 625,
    [theme.breakpoints.up('md')]: {
      width: 940,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1210,
    },
  },
  title: {
    display: 'inline-flex',
    marginBottom: 32
  },
  menuPaper: {
    marginLeft: -20
  }
});

interface IProps extends WithStyles<typeof styles> {
  furnaces: IFurnace[],
  currentDate: Date,
}

function ChartCard(props: IProps) {
  const {classes, furnaces, currentDate} = props;
  const [optionId, setOptionId] = useState(1);
  const [extraSimDate, setExtraSimDate] = useState<Date>();

  if (furnaces.length === 0) return (
    <Card classes={{root: classes.cardRoot}}>
    </Card>
  )

  const handleOnSelect = (index: number) => {
    setExtraSimDate(undefined);
    setOptionId(index);
  };

  const optionData = [] as any[];

  for (let i=0; i<furnaces.length; i++) {
    const furnace = furnaces[i]
    optionData.push({
      furnaceId: i,
      optionName: furnace.name + ' (średnia)',
      mode: LineChartMode.MEAN
    });
    if (furnace.separateCoilsAvailable)
      optionData.push({
        furnaceId: i,
        optionName: furnace.name + ' (osobne wężownice)',
        mode: LineChartMode.SEPARATE_COILS
      });
  }

  if (optionId >= optionData.length) {
    handleOnSelect(0);
    return (
      <Card classes={{root: classes.cardRoot}}>
      </Card>
    )
  }

  const option = optionData[optionId];
  const mode = option.mode;
  const furnace = furnaces[option.furnaceId];
  const furnaceName = furnace.name;

  const menuItems = optionData.map(m => (
    <div>
      {m.optionName}
    </div>
  ));

  const extraSimDateSelected = (extraSimDate: Date) => {
    setExtraSimDate(extraSimDate);
  }

  return (
    <Card classes={{root: classes.cardRoot}}>
      <MenuOpener
        classes={{root: classes.title, paper: classes.menuPaper}}
        menuItems={menuItems}
        onSelect={handleOnSelect}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography variant="button1HighEmphasis">{furnaceName}</Typography>
        <DropIcon/>
      </MenuOpener>
      <LineChartWithCalendar
        days={furnace.days}
        currentDate={currentDate}
        mode={mode}
        extraSimDate={extraSimDate}
        extraSimDateSelected={extraSimDateSelected}
      />
    </Card>
  );
}

export default withStyles(styles)(ChartCard)