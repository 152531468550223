import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import Card from "./Card";
import LegendIcon from "./LegendIcon";
import {light} from "@material-ui/core/styles/createPalette";
import PopoverOpener from "./PopoverOpener";
import Typography from "./Typography";

const styles = (theme: Theme) => createStyles({
  cardRoot: {
    borderRadius: 16,
    width: 456,
    padding: 0,
  },
  tileRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& + &': {
      marginTop: 24
    }
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32
  },
  //legend:
  lightNaphtha: {
    backgroundColor: theme.palette.states.lightNaphtha
  },
  mediumNaphtha: {
    backgroundColor: theme.palette.states.mediumNaphtha
  },
  heavyNaphtha: {
    backgroundColor: theme.palette.states.heavyNaphtha
  },
  gas: {
    backgroundColor: theme.palette.states.gas
  },
  hydrocrackingResidues: {
    backgroundColor: theme.palette.states.hydrocrackingResidues
  },
  heavyDiesel: {
    backgroundColor: theme.palette.states.heavyDiesel
  },
  hotReserve: {
    backgroundColor: theme.palette.states.hotReserve
  },
  TLECleaning: {
    backgroundColor: theme.palette.states.TLECleaning
  },
  inoperative: {
    background: `url(${theme.palette.states.inoperative})`
  },
  decoking: {
    backgroundColor: theme.palette.states.decoking
  },
  noData: {
    backgroundColor: theme.palette.states.noDataBackground,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.states.noDataBorder
  },
  error: {
    backgroundImage: theme.palette.states.error
  }
});

interface IProps extends WithStyles<typeof styles> {
}

function Legend(props: IProps) {
  const {classes} = props;

  const renderLegendIcon = (className: string, shortName: string, longName: string) => {
    return (
      <PopoverOpener
        content={(
          <Typography variant="body3">{longName}</Typography>
        )}
      >
        <LegendIcon
          classes={{tile: className}}
          name={shortName}
        />
      </PopoverOpener>
    )
  }

  return (
    <Card classes={{root: classes.cardRoot}}>
      <div className={classes.topSection}>
        <div className={classes.tileRow}>
          {
            renderLegendIcon(
              classes.lightNaphtha,
              'BL', 'Benzyna lekka'
            )
          }
          {
            renderLegendIcon(
              classes.mediumNaphtha,
              'BS', 'Benzyna średnia'
            )
          }
          {
            renderLegendIcon(
              classes.heavyNaphtha,
              'BC', 'Benzyna ciężka'
            )
          }
          {
            renderLegendIcon(
              classes.gas,
              'GW', 'Gaz wsadowy'
            )
          }
          {
            renderLegendIcon(
              classes.hydrocrackingResidues,
              'HCR', 'Pozostałość ciężka z hydrocrackingu'
            )
          }
          {
            renderLegendIcon(
              classes.noData,
              'Brak danych', 'Nie pobrano danych'
            )
          }
        </div>
        <div className={classes.tileRow}>
          {
            renderLegendIcon(
              classes.heavyDiesel,
              'ONC', 'Olej napędowy ciężki'
            )
          }
          {
            renderLegendIcon(
              classes.hotReserve,
              'GR', 'Gorąca rezerwa'
            )
          }
          {
            renderLegendIcon(
              classes.TLECleaning,
              'Mycie', 'Mycie kotłów'
            )
          }
          {
            renderLegendIcon(
              classes.inoperative,
              'Postój', 'Postój'
            )
          }
          {
            renderLegendIcon(
              classes.error,
              'Błąd', 'Błąd czujników'
            )
          }
          {
            renderLegendIcon(
              classes.decoking,
              'Odkoksowanie', 'Odkoksowanie'
            )
          }
        </div>
      </div>
    </Card>
  );
}

export default withStyles(styles)(Legend)