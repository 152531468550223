import {FETCH_ERROR, IResponse} from "../utils/web";
import {del, get, post} from "../utils/web";
import {Messenger} from "./messenger";
import lang from "../lang/lang";

const msg = lang.messages();

export function showDefaultApiErrorMessage(code: number, payload: any, messenger: Messenger, header?: string) {
  let message = '';
  if (code === 200 && payload == null)
    message = msg.errorEmptyPayload;
  else if (code === 200 && payload.ok)
    throw new Error('Success responses should not be handled here.');
  else if (code === 200 && !payload.ok) {
    if (payload.error)
      message = msg.errorApiErrorWithDescription + payload.error;
    else
      message = msg.errorApiUnknown;
  }
  else if (code === 503)
    message = msg.errorNoAccess;
  else if (code === 404)
    message = msg.errorPageNotFound;
  else if (code === FETCH_ERROR)
    message = msg.errorFetchUnexpected;
  else
    message = msg.errorUnexpected;

  if (header == null)
    messenger.showError(message);
  else
    messenger.showError(header + ' ' + message);
}

export interface IApi {
  post: (method: string, id: number | string | null, body: any, bodyToJson?: boolean)=>Promise<IResponse>,
  get: (method: string, id?: number | string | null)=>Promise<IResponse>,
  del: (method: string, id?: number | string | null)=>Promise<IResponse>,
}

export class Api implements IApi {

  async post(method: string, id: number | string | null, body: any, bodyToJson: boolean = true) {
    return post(method, id, body, bodyToJson);
  }

  async get(method: string, id?: number | string | null) {
    return get(method, id);
  }

  async del(method: string, id?: number | string | null) {
    return del(method, id);
  }

}
