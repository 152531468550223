export enum Feedstock {
  NONE,
  LIGHT_NAPHTHA,
  MEDIUM_NAPHTHA,
  HEAVY_NAPHTHA,
  GAS,
  HEAVY_DIESEL,
  HYDROCRACKING_RESIDUES,
  GROUP__DIESEL_OR_HCR// HEAVY_DIESEL or HYDROCRACKING_RESIDUES
}

export enum FurnaceState {
  UNKNOWN,
  INOPERATIVE,
  OPERATIVE,
  DECOKING,
  HOT_RESERVE,
  ERROR,
  MISSING,
  FORECAST
}

export interface State {
  state: FurnaceState,
  feedstock: Feedstock,
  start?: Date,
  end?: Date,
}

export enum UserAccessType {
  None = 0,
  Authorized = 1,
  Admin = 2,
  SuperAdmin = 3,
}

export interface ISimulationObject {
  meanTMT?: number
}

export interface IOutlier {
  name: string,
  sign: number,
}

export interface IDay {
  date: Date,
  workingTime?: number,
  meanTMT?: number,
  meanTMT_coil1?: number,
  meanTMT_coil2?: number,
  meanTMT_coil3?: number,
  forecast?: boolean,
  missing?: boolean,
  states: State[],
  TMTHourly: ITMTHourly[],
  sim?: ISimulationObject[],
  outliers?: IOutlier[],
}

export interface IFurnace {
  name: string,
  separateCoilsAvailable?: boolean,
  days: IDay[]
}

export interface ITMTHourly {
  hour: number,
  TMT: number
}

export interface IDayBacked {
  date: number,
  workingTime?: number,
  meanTMT?: number,
  meanTMT_coil1?: number,
  meanTMT_coil2?: number,
  meanTMT_coil3?: number,
  forecast?: boolean,
  missing?: boolean,
  states: State[],
  TMTHourly: ITMTHourly[],
  sim?: ISimulationObject[],
  outliers?: IOutlier[],
}

export interface IFurnaceBackend {
  name: string,
  separateCoilsAvailable?: boolean,
  days: IDayBacked[]
}

export interface IUser {
  id: string,
  access_type?: UserAccessType,
  email: string,
  last_failed_login?: number,
  last_successful_login?: number,
}

export const convertFurnaceBackendToFronted = (furnaces: IFurnaceBackend[]) => {
  const calculateTMT = (i, days, fieldName) => {
    if (days[i][fieldName] != null)
      return days[i][fieldName];
    if (i>0 && days[i-1][fieldName] != null)
      return days[i-1][fieldName];
    if (i<days.length-1 && days[i+1][fieldName] != null)
      return days[i+1][fieldName];
  }
  return furnaces.map(f=>({
    ...f,
    days: f.days.map((d, i)=>({
      ...d,
      meanTMT: calculateTMT(i, f.days, 'meanTMT'),
      meanTMT_coil1: calculateTMT(i, f.days, 'meanTMT_coil1'),
      meanTMT_coil2: calculateTMT(i, f.days, 'meanTMT_coil2'),
      meanTMT_coil3: calculateTMT(i, f.days, 'meanTMT_coil3'),
      // convert a unix timestamp to date
      date: new Date(d.date * 1000),
      workingTime: d.workingTime != null ? Math.round(d.workingTime/24) : d.workingTime
    }))
  }))
}