import {IApi, showDefaultApiErrorMessage} from "./api";
import {Messenger} from "./messenger";

import lang from "../lang/lang";
import {IUser} from "../models/models";

const msg = lang.messages();

export const createSessionStore = (api: IApi, messenger: Messenger) => {
  return {
    isAuthenticated: false,
    user: undefined as undefined | IUser,
    async auth(email: string, password: string) {
      email = email.toLowerCase();
      const {code, payload} = await api.post('auth', null, {email, password});
      if (code === 200 && payload.ok) {
        await this.updateUserInfo();
        return;
      }
      if (code === 401) {
        messenger.showError(msg.loginErrorWrongPassword);
        return;
      }
      showDefaultApiErrorMessage(code, payload, messenger);
    },
    async updateUserInfo() {
      const {code, payload} = await api.get('me');
      if (code===200 && payload.ok) {
        this.isAuthenticated = true;
        this.user = {
          ...payload.result
        };
      }
      if (code===503) {
        this.isAuthenticated = false;
        this.user = undefined;
      }
    },
    async logout() {
      const {code, payload} = await api.del('auth');
      if (code===200 && payload.ok) {
        this.isAuthenticated = false;
        return;
      }
      showDefaultApiErrorMessage(code, payload, messenger);
    }
  }
};

export type TSessionStore = ReturnType<typeof createSessionStore>;

export const createMockSessionStore = (api: IApi, messenger: Messenger) => {
  return {
    isAuthenticated: false,
    user: {
    } as any,
    async auth(email: string, password: string) {
      this.isAuthenticated = true;
    },
    async logout() {
      this.isAuthenticated = false;
    },
    async updateUserInfo() {
    }
  } as TSessionStore;
};
