import {IApi, showDefaultApiErrorMessage} from "./api";
import {Messenger} from "./messenger";
import {sleep} from "../utils/etc";

enum JobStatus {
  Created = 0,
  Queued = 1,
  Received = 2,
  Running = 3,
  Finished = 4,
  Errored = 5,
}

export const createJobStore = (api: IApi, messenger: Messenger) => {
  return {
    async runJob(jobType: string, data?: any) {
      const body = {
        job_type: jobType,
        args: data,
      }
      const {code, payload} = await api.post('jobs', null, body);
      if (code === 200 && payload.ok) {
        const jobId = payload.id;
        return await this.getJobResult(jobId);
      }
      showDefaultApiErrorMessage(code, payload, messenger);
    },
    async getJobResult(jobId: any) {
      while(true) {
        const {code, payload} = await api.get('jobs', jobId);
        if (code === 200 && payload.ok) {
          const status = payload.data.status as JobStatus;
          if (status === JobStatus.Finished)
            return payload.data.result;
          if (status === JobStatus.Errored) {
            messenger.showError('Błąd podczas wykonywania zadania po stronie serwera.');
            return undefined;
          }
        } else {
          showDefaultApiErrorMessage(code, payload, messenger);
          return undefined;
        }
        await sleep(200);
      }
    }
  }
}

export type TJobStore = ReturnType<typeof createJobStore>;