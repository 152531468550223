import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import MUIAppBar from "@material-ui/core/AppBar";
import Typography from "./Typography";
import Toolbar from "@material-ui/core/Toolbar";
import DateChooser from "./DateChooser";
import DropIcon from "./DropIcon";
import MenuOpener from "./MenuOpener";
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../logo.svg';
import PopperOpener from "./PopperOpener";
import Legend from "./Legend";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  root: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.beyonder400,
    boxShadow: 'unset'
  },
  colorPrimary: {
    backgroundColor: theme.palette.raymond,
    color: '#313131'
  },
  toolbarRoot: {
  },
  toolbarRegular: {
    height: 56,
    minHeight: 56
  },
  box: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    '&:first-child': {
      justifyContent: 'flex-start'
    },
    '&:last-child': {
      justifyContent: 'flex-end'
    }
  },
  row: {
    display: 'flex'
  },
  menuIcon: {
    color: theme.palette.impala500,
    marginRight: 10
  },
  popper: {
    zIndex: 1200
  },
  legendPopperContainer: {
    marginRight: 64
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'unset',
    },
  },
});

interface IProps extends WithStyles<typeof styles> {
  userName: string,
  finalDate: Date,
  maxDate: Date,
  minDate: Date,
  dayCount: number,
  onLogOut?: ()=>void,
  onOpenSettings?: ()=>void,
  onSetFinalDate?: (finalDate: Date)=>void
}

const editUserName = (userName: string) => {
  return userName.replace(/@.*$/,"");
}

function AppBar(props: IProps) {
  const {classes, userName, finalDate, maxDate, minDate, dayCount, onLogOut,
    onOpenSettings, onSetFinalDate} = props;

  const userMenuItems = [
    // <>
    //   <SettingsIcon className={classes.menuIcon} fontSize="small"/>
    //   Ustawienia
    // </>,
    <>
      <ExitToAppIcon className={classes.menuIcon} fontSize="small"/>
      Wyloguj się
    </>
  ];

  const handleUserMenuSelect = (index: number) => {
    // if (index === 0 && onOpenSettings)
    //   onOpenSettings();
    if (index === 0 && onLogOut)
      onLogOut();
  };

  return (
    <MUIAppBar
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary
      }}
      {...{}/* https://github.com/Microsoft/TypeScript/issues/27552#issuecomment-495830020
      // @ts-ignore */}
      boxShadow={0}
    >
      <Toolbar
        classes={{
          root: classes.toolbarRoot,
          regular: classes.toolbarRegular
        }}
      >
        <div className={classNames(classes.box, classes.logo)}>
          <img src={logo} alt=""/>
        </div>
        <div className={classes.box}>
          <DateChooser
            finalDate={finalDate}
            maxDate={maxDate}
            minDate={minDate}
            dayCount={dayCount}
            onSetFinalDate={onSetFinalDate}
          />
        </div>
        <div className={classes.box}>
          <PopperOpener
            classes={{popper: classes.popper}}
            content={(
              <Legend/>
            )}
          >
            <div
              className={
                classNames([
                  classes.row,
                  classes.legendPopperContainer
                ])
              }
            >
              <Typography variant="button2">Legenda</Typography>
              <DropIcon/>
            </div>
          </PopperOpener>
          <MenuOpener
            menuItems={userMenuItems}
            onSelect={handleUserMenuSelect}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Typography variant="button2">{editUserName(userName)}</Typography>
            <DropIcon/>
          </MenuOpener>
        </div>
      </Toolbar>
    </MUIAppBar>
  );
}

export default withStyles(styles)(AppBar)