import * as React from "react";
import {CustomTheme, high_threshold, single_coil_threshold, palette, Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {Grow, WithStyles} from "@material-ui/core";
import {FurnaceState, IDay} from "../models/models";
import {Point, ResponsiveLine} from '@nivo/line';
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
// @ts-ignore (ts claims that the module does not exist)
import {linearGradientDef} from '@nivo/core';
import Card from "./Card";
import Typography from "./Typography";
import {useRef} from "react";

const CONTEXT_MENU_ID = "menu-id-12345";

// @ts-ignore
const styles = (theme: Theme) => createStyles({
  root: {
    height: 368
  },
  ticksText: {
    color: 'red'
  },
  popover: {
    padding: 12,
    borderRadius: 10,
  }
});

export enum LineChartMode {
  MEAN,
  SEPARATE_COILS
}

interface IProps extends WithStyles<typeof styles> {
  days: IDay[],
  currentDate: Date,
  extraSimDate?: Date,
  mode?: LineChartMode,
  extraSimDateSelected?: (date: Date) => void
}

const ColorLayer = (props) => {
  const { data, xScale, yScale } = props;

  const shouldBeColored = (states, index) => {
    return (states.length > 0 && (
      states[index] === 'decoking' || states[index] === 'missing'))
  }

  const pointsFilteredLeft = data[0].data.filter(
    p=>shouldBeColored(p.states, 0));
  const pointsFilteredRight = data[0].data.filter(
    p=>shouldBeColored(p.states, 1));

  const width = 38.5;

  const isFirst = (p) => {
    return p.x === data[0].data[0].x;
  };

  const getColor = (state) => {
    if (state === 'missing')
      return palette.raymond;
    if (state === 'decoking')
      return palette.rockslide900;
  };

  const mapPoints = (f) => {
    return pointsFilteredLeft.map((p, i) => f(p, i, true)).concat(
      pointsFilteredRight.map((p, i) => f(p, i, false))
    )
  }

  return (
    mapPoints((p, i, leftSide)=>(
      <Grow
        in={true}
        timeout={200}
        key={p.x + leftSide}
      >
        <rect
          x={xScale(p.x) - (leftSide ? width / 2 : 0)}
          y={yScale(1200)}
          width={isFirst(p) && leftSide ? 0 : width / 2 + 0.8}
          height={yScale(950) - yScale(1200)}
          fill={getColor(leftSide ? p.states[0] : p.states[1])}
        >
        </rect>
      </Grow>
    ))
  )
};

const InoperativeLayer = (props) => {
  const { data, xScale, yScale } = props;
  const pointsFilteredLeft = data[0].data.filter(
    p=>p.states.length > 0 && p.states[0] === 'inoperative');
  const pointsFilteredRight = data[0].data.filter(
    p=>p.states.length > 0 && p.states[1] === 'inoperative');
  const width = 38;

  const isFirst = (p) => {
    return p.x === data[0].data[0].x;
  };

  const mapPoints = (f) => {
    return pointsFilteredLeft.map((p, i) => f(p, i, true)).concat(
      pointsFilteredRight.map((p, i) => f(p, i, false))
    )
  }
  return (
    <>
      <defs>
        <pattern
          id="stripes"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <image
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAO0lEQVQYlY3KuRUAIAhEwS3PEuw/sotvJM8DkIlHFIzWUSUBeVwpjXsK453c6KUnRumIWbL4SwCqJIAJdrR/7lYD0UkAAAAASUVORK5CYII="
            x="0"
            y="0"
            width="10"
            height="10">
          </image>
        </pattern>
      </defs>
      {mapPoints((p, i, leftSide)=>(
        <Grow
          in={true}
          timeout={200}
          key={p.x + leftSide}
        >
          <rect
            x={xScale(p.x) - (leftSide ? width / 2 : 0)}
            y={yScale(1200)}
            width={isFirst(p) && leftSide ? 0 : width / 2 + 0.8}
            height={yScale(950) - yScale(1200)}
            style={{
              fill: 'url(#stripes)'
            }}
          />
        </Grow>
      ))}
    </>
  )
};


const CreateHorizontalLineLayer = (threshold: number) => {
  return (props) => {
    const { data, xScale, yScale } = props;

    if (data[0].data.length === 0)
      return null;

    return (
      <>
        <line
          y1={yScale(threshold)}
          y2={yScale(threshold)}
          x2={xScale(data[0].data[0].x)}
          x1={xScale(data[0].data[data[0].data.length-1].x)}
          style={{
            stroke: palette.dinosaur700,
            strokeWidth: 1
          }}
        >
        </line>
      </>
    )
  };
}

const CurrentDayLayer = (props) => {
  const { data, xScale, yScale } = props;

  const currentDay = data[0].data.find(p=>p.today);
  if (currentDay === undefined) return;

  const shift = 38 / 2;
  return (
    <>
      <line
        y1={yScale(950)}
        y2={yScale(1200)}
        x2={xScale(currentDay.x) - shift}
        x1={xScale(currentDay.x) - shift}
        style={{
          stroke: palette.rockslide300,
          strokeWidth: 1,
          strokeDasharray: '10, 6',
        }}
      >
      </line>
    </>
  )
}

const SolidLine = (props) => {
  const { series, lineGenerator, xScale, yScale, lineWidth } = props;
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.filter(d => !d.data.forecast).map(d => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={{
        strokeWidth: lineWidth,
      }}
    />
  ))
}

const DashedLine = (props) => {
  const { series, lineGenerator, xScale, yScale, lineWidth=0 } = props;
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        // start with the last element of non forecast data
        [data.filter(d => !d.data.forecast).pop()].concat(data.filter(d => d.data.forecast)).map(d => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={{
        strokeDasharray: '10, 5',
        strokeWidth: lineWidth,
      }}
    />
  ))
}

const ExtraSimLayer = (props) => {
  const { series, lineGenerator, xScale, yScale, lineWidth=0 } = props;
  for (let {id, data} of series)
    for (let d of data) {
      if (d.data.showExtraSim) {
        const simObjects = [{meanTMT: d.data.y}].concat(d.data.sim)
        return (
          <path
            key={id}
            d={lineGenerator(
              simObjects.map((simObject, i) => ({
                x: xScale(new Date(d.data.x.getTime() + 1000 * 60 * 60 * 24 * (i))),
                y: yScale(simObject.meanTMT),
              }))
            )}
            fill="none"
            stroke={palette.dinosaur600}
            style={{
              strokeDasharray: '10, 5',
              strokeWidth: 2,
            }}
          />
        )
      }
    }
}

function createData(days: IDay[], currentDate: Date, extraSimDate: Date | undefined, mode: LineChartMode) {

  const createState = (day, leftSide=true) => {
    if (day.missing)
      return 'missing';
    if (day.states.length > 0) {
      const index = leftSide ? 0 : day.states.length - 1;
      if (day.states[index].state === FurnaceState.DECOKING)
        return 'decoking';
      if (day.states[index].state === FurnaceState.INOPERATIVE)
        return 'inoperative';
    }
  }

  const compareDates = (date1: Date | undefined, date2: Date | undefined) => {
    if (date1 == null || date2 == null)
      return false;
    return (
      date1.getDate() === date2.getDate()
      && date1.getMonth() === date2.getMonth()
      && date1.getFullYear() === date2.getFullYear()
    )

  }

  const createDataPoints = (name: string, fieldName: string, color: any=undefined) => {
    return {
      id: name,
      color: color,
      data: days.map((day, i) => ({
        x: day.date,
        y: day[fieldName] == null ? null : day[fieldName],
        today: compareDates(day.date, currentDate),
        forecast: day.forecast,
        states: [
          createState(day, true),
          createState(day, false),
        ],
        showExtraSim: compareDates(day.date, extraSimDate),
        sim: day.sim
      }))
    }
  }

  const dataArray = [] as ReturnType<typeof createDataPoints>[];

  if (mode === LineChartMode.MEAN)
    dataArray.push(createDataPoints(
      'TMT', 'meanTMT'))
  else {
    dataArray.push(createDataPoints(
      'TMT coil 1', 'meanTMT_coil1', palette.impala500))
    dataArray.push(createDataPoints(
      'TMT coil 2', 'meanTMT_coil2', palette.triton700))
    dataArray.push(createDataPoints(
      'TMT coil 3', 'meanTMT_coil3', palette.harvey500))
  }

  return dataArray;
}

function createChartProps(mode: LineChartMode) {
  if (mode == LineChartMode.MEAN) return {
    enableArea: true,
    lineWidth: 0,
  }
  if (mode == LineChartMode.SEPARATE_COILS) return {
    enableArea: false,
    lineWidth: 2,
    colors: { datum: 'color' }
  }
}

function LineChart(props: IProps) {
  const {days, classes, currentDate, extraSimDate,
    mode = LineChartMode.MEAN, extraSimDateSelected } = props;
  const { show } = useContextMenu({
    id: CONTEXT_MENU_ID
  });
  const currentPoint = useRef<any>();
  const selectedPoint = useRef<any>();

  const  handleItemClick = (event) => {
    if (extraSimDateSelected != null && selectedPoint.current != null)
      extraSimDateSelected(selectedPoint.current.data.x)
  }

  const displayMenu = (e) => {
    if (mode == LineChartMode.MEAN
      && currentPoint.current != null
      && currentPoint.current.data.states[0] == null
      && currentPoint.current.data.states[1] == null
      && currentPoint.current.data.sim != null
    ) {
      selectedPoint.current = currentPoint.current;
      show(e);
    }
  }

  const onMouseEnter = (point: Point, event: React.MouseEvent) => {
    currentPoint.current = point;
  }

  const onMouseMove = (point: Point, event: React.MouseEvent) => {
    currentPoint.current = point;
  }

  const data = createData(
    days,
    currentDate,
    mode == LineChartMode.MEAN ? extraSimDate : undefined,
    mode
  ) as any[];

  const preparePopoverText = (p) => {
    const data = p.point.data;
    if (data.states == null) return;
    if (data.states.length > 0) {
      if (data.states[0] === 'decoking' && data.states[1] === 'decoking')
        return `${data.xFormatted}: Odkoksowanie`;
      if (data.states[0] === 'missing' && data.states[1] === 'missing')
        return `${data.xFormatted}: Brak danych`;
      if (data.states[0] === 'inoperative' && data.states[1] === 'inoperative')
        return `${data.xFormatted}: Postój`;
    }
    if (data.forecast)
      return `${data.xFormatted} (predykcja): ${data.yFormatted.toFixed(2)}°C`
    else
      return `${data.xFormatted}: ${data.yFormatted.toFixed(2)}°C`
  }

  const createTooltip = (p: any) => {
    let header;
    if (p.point.serieId === 'TMT coil 1')
      header = 'Wężownica 1:'
    else if (p.point.serieId === 'TMT coil 2')
      header = 'Wężownica 2:'
    else if (p.point.serieId === 'TMT coil 3')
      header = 'Wężownica 3:'
    else
      header = 'Średnia temperatura:'

    return (
      <Card
        classes={{root: classes.popover}}
      >
        {header != null && (
          <Typography variant="body2">
            {header}
          </Typography>
        )}
        <Typography variant="body2">
          {preparePopoverText(p)}
        </Typography>
      </Card>
    )
  }

  let threshold = 0;

  if (mode == LineChartMode.MEAN)
    threshold = high_threshold;
  if (mode == LineChartMode.SEPARATE_COILS)
    threshold = single_coil_threshold;

  const chartProps = createChartProps(mode);

  const xTicks = days.map(day=>day.date);
  const xTicksOdd = xTicks.filter((day, i)=>i%2===0);
  return (
    <div
      className={classes.root}
      onContextMenu={displayMenu}
    >
      <ResponsiveLine
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          SolidLine,
          DashedLine,
          InoperativeLayer,
          ColorLayer,
          ExtraSimLayer,
          CreateHorizontalLineLayer(threshold),
          'crosshair',
          CurrentDayLayer,
          'points',
          'slices',
          'mesh',
          'legends'
        ]}
        data={data}
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: palette.impala500, opacity: 1  },
            { offset: 100, color: palette.impala100, opacity: 1 },
          ]),
        ]}
        fill={[
          { match: '*', id: 'gradientA' },
        ]}
        areaOpacity={1}
        curve="linear"
        enableGridX={true}
        enableGridY={true}
        margin={{ top: 5, right: 0, bottom: 30, left: 32 }}
        gridXValues={xTicks}
        xScale={{
          type: 'time',
          format: 'native',
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{ type: 'linear', min: 950, max: 1200, stacked: false, reverse: false }}
        areaBaselineValue={950}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'date',
          legendOffset: 36,
          legendPosition: 'middle',
          format: "%Y-%m-%d",
          tickValues: xTicksOdd
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'temperature',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        useMesh={true}
        enablePoints={false}
        tooltip={createTooltip}
        theme={{
          axis: {
            ticks: {
              text: CustomTheme.text.data1
            }
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        {...chartProps}
      />
      <Menu id={CONTEXT_MENU_ID}>
        <Item onClick={handleItemClick}>
          <Typography variant="body2">
            Pokaż predykcję
          </Typography>
        </Item>
      </Menu>
    </div>
  );
}

export default withStyles(styles)(LineChart)