import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import BaseForm from "./BaseForm";
import {useState} from "react";
import Button from "./Button";
import {Messenger} from "../store/messenger";
import TextField from "./TextField";
import Typography from "./Typography";

const styles = (theme: Theme) => createStyles({
  baseFormRoot: {
    paddingBottom: 32,
  },
  passwordField: {
  },
  button: {
    marginTop: 20
  },
  password1Label: {
    marginBottom: 4
  },
  password2Label: {
    marginTop: 12,
    marginBottom: 4
  }
});

interface IProps extends WithStyles<typeof styles> {
  header?: string,
  description?: string,
  buttonText?: string,
  messenger?: Messenger,
  onReset?: (password: string) => void
}

function PasswordResetForm(props: IProps) {
  const {classes, header, description, buttonText, messenger, onReset} = props;
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value);
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (password.length === 0) {
      if (messenger)
        messenger.showError('Wpisz hasło.');
      return;
    }
    if (password2.length === 0) {
      if (messenger)
        messenger.showError('Wpisz hasło ponownie w polu poniżej.');
      return;
    }
    if (password !== password2) {
      if (messenger)
        messenger.showError('Hasła nie zgadzają się.');
      return;
    }
    if (onReset)
      onReset(password);
  };

  return (
    <BaseForm
      classes={{
        root: classes.baseFormRoot
      }}
      header={header}
      description={description}
    >
      <form>
        <Typography
          classes={{
            root: classes.password1Label
          }}
          variant="label1"
        >
          Nowe hasło:
        </Typography>
        <TextField
          classes={{
            root: classes.passwordField
          }}
          fullWidth
          type="password"
          onChange={handlePasswordChange}
        />
        <Typography
          classes={{
            root: classes.password2Label
          }}
          variant="label1"
        >
          Powtórz nowe hasło:
        </Typography>
        <TextField
          classes={{
            root: classes.passwordField
          }}
          fullWidth
          type="password"
          onChange={handlePassword2Change}
        />
        <Button
          fullWidth
          type="submit"
          classes={{
            root: classes.button
          }}
          onClick={handleReset}
        >
          {buttonText}
        </Button>
      </form>
    </BaseForm>
  );
}

export default withStyles(styles)(PasswordResetForm)