import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {IDay} from "../models/models";
import LineChart, {LineChartMode} from "./LineChart";
import CalendarRow from "./CalendarRow";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  chart: {
    marginRight: 34 / 2 + 1
  },
  calendar: {
    marginLeft: 16,
    marginTop: 30
  }
});

interface IProps extends WithStyles<typeof styles> {
  days: IDay[],
  currentDate: Date,
  extraSimDate?: Date,
  mode?: LineChartMode,
  extraSimDateSelected?: (date: Date) => void
}

function LineChartWithCalendar(props: IProps) {
  const {classes, days, currentDate, extraSimDate, mode=LineChartMode.MEAN,
    extraSimDateSelected
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.chart}>
        <LineChart
          days={days}
          currentDate={currentDate}
          extraSimDate={extraSimDate}
          mode={mode}
          extraSimDateSelected={extraSimDateSelected}
        />
      </div>
      <div className={classes.calendar}>
        <CalendarRow days={days}/>
      </div>
    </div>
  );
}

export default withStyles(styles)(LineChartWithCalendar)