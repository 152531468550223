import React from 'react'
import {createMockSessionStore, createSessionStore, TSessionStore} from './sessionStore'
import { useLocalStore } from 'mobx-react-lite'
import {Api, IApi} from "./api";
import {Messenger} from "./messenger";
import {createJobStore, TJobStore} from "./jobStore";
import {createFurnaceStore, TFurnaceStore} from "./furnaceStore";
import {createUsersStore, TUsersStore} from "./usersStore";
import {createInvitationStore, TInvitationStore} from "./invitationStore";

interface IStore {
  sessionStore: TSessionStore,
  jobStore: TJobStore,
  furnaceStore: TFurnaceStore,
  userStore: TUsersStore,
  invitationStore: TInvitationStore,
  messenger: Messenger,
  api: IApi,
}

export const StoreContext = React.createContext<IStore | null>(null);

export const StoreProvider = ({ children, mock }: {children?: any, mock: boolean}) => {
  const api = new Api();
  const messenger = new Messenger();
  const store = {
    sessionStore:
      mock ? createMockSessionStore(api, messenger) : createSessionStore(api, messenger),
    jobStore: createJobStore(api, messenger),
    furnaceStore: createFurnaceStore(api, messenger),
    userStore: createUsersStore(api, messenger),
    invitationStore: createInvitationStore(api, messenger),
    messenger,
    api
  } as IStore;
  return (
    <StoreContext.Provider
      value={useLocalStore(() => store)}>{children}
    </StoreContext.Provider>
  )
};

export const useStore = () => {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error('You have forgot to use StoreProvider.')
  }
  return store;
};
