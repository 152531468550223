import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import Typography from "./Typography";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 16 + 24 + 4
  },
  tile: {
    borderRadius: 4,
    marginBottom: 4,
    width: 32,
    height: 24
  },
  label: {
    marginTop: 24 + 4,
    position: 'absolute'
  }
});

interface IProps extends WithStyles<typeof styles> {
  name: string
}

function LegendIcon(props: IProps) {
  const {classes, name} = props;

  return (
    <div className={classes.root}>
      <div className={classes.tile}/>
      <Typography
        variant="label2"
        classes={{
          root: classes.label
        }}
      >
        {name}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(LegendIcon)