import {IApi} from "./api";
import {Messenger} from "./messenger";
import {createJobStore} from "./jobStore";
import {convertFurnaceBackendToFronted, IDayBacked, IFurnace, IFurnaceBackend} from "../models/models";

export const createFurnaceStore = (api: IApi, messenger: Messenger) => {
  return {
    furnaces: [] as IFurnace[],
    jobStore: createJobStore(api, messenger),
    async updateFurnaces() {
      const result = await this.jobStore.runJob(
        'get_all_data', { 'test': 'test'});
      if (result == null)
        return;
      const resultParsed = JSON.parse(result) as IFurnaceBackend[];
      this.furnaces = convertFurnaceBackendToFronted(resultParsed);
    }
  }
}

export type TFurnaceStore = ReturnType<typeof createFurnaceStore>;