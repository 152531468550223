import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {IconButton, WithStyles} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import Typography from "./Typography";

const styles = (theme: Theme) => createStyles({
  root: {
    width: 264 + 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    boxShadow: '0 2px 4px 0 rgba(15, 15, 15, 0.16)'
  },
  icon: {
    color: theme.palette.impala500
  }
});

interface IProps extends WithStyles<typeof styles> {
  finalDate: Date,
  maxDate: Date,
  minDate: Date,
  dayCount: number,
  onSetFinalDate?: (finalDate: Date)=>void
}

function DateChooser(props: IProps) {
  const {classes, finalDate, maxDate, minDate, dayCount, onSetFinalDate} = props;
  const date = moment(finalDate);
  let dateStr =  ' - ' + date.format('D MMMM Y');
  dateStr = date.subtract(dayCount, 'd').format('D MMMM') + dateStr;

  const handleBack = () => {
    let newDate = moment(finalDate).subtract(dayCount, 'd');
    if (newDate.diff(minDate, 'd') <= dayCount) {
      newDate = moment(minDate).add(dayCount, 'd');
    }
    if (onSetFinalDate) {
      onSetFinalDate(newDate.toDate());
    }
  };

  const handleForward = () => {
    let newDate = moment(finalDate).add(dayCount, 'd');
    if (newDate.diff(maxDate, 'd') > 0) {
      newDate = moment(maxDate)
    }
    if (onSetFinalDate) {
      onSetFinalDate(newDate.toDate());
    }
  };

  return (
    <div className={classes.root}>
      <IconButton size="small" className={classes.button} onClick={handleBack}>
        <ArrowBackIcon className={classes.icon} fontSize="small"/>
      </IconButton>
      <Typography variant="body2">{dateStr}</Typography>
      <IconButton size="small" className={classes.button} onClick={handleForward}>
        <ArrowForwardIcon className={classes.icon} fontSize="inherit"/>
      </IconButton>
    </div>
  );
}

export default withStyles(styles)(DateChooser)