import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import Card from "./Card";
import Typography from "./Typography";
import {FurnaceState, IDay} from "../models/models";
import moment from "moment";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  stateName: {
    marginTop: 20
  },
  temperaturesPerHourHeader: {
    marginTop: 20
  },
  temperaturesPerHourList: {

  },
});

interface IProps extends WithStyles<typeof styles> {
  day: IDay,
}

const renderAlertMessage = (day: IDay) => {
  if (day.outliers == null || day.outliers.length == 0)
    return null;
  let lines = ['Uwaga! Wykryto anomalie.'];
  const outliers_high = day.outliers.filter(o => o.sign > 0);
  const outliers_low = day.outliers.filter(o => o.sign < 0);
  if (outliers_high.length > 0) {
    lines.push('Wartości podwyższone:');
    for (let outlier of outliers_high)
      lines.push(`- ${outlier.name}`);
  }
  if (outliers_low.length > 0) {
    lines.push('Wartości obniżone:');
    for (let outlier of outliers_low)
      lines.push(`- ${outlier.name}`);
  }
  return (
    <>
      {lines.map(l => (
        <Typography
          variant="body2"
        >
          {l}
        </Typography>
      ))}
      <br/>
    </>
  )
}

const prepareStateName = (day: IDay) => {
  if (day.missing)
    return `Brak danych`;
  if (day.forecast)
    return `Predykcja: ${day.meanTMT!.toFixed(2)}°C`;
  if (day.states.some(s => s.state === FurnaceState.OPERATIVE) && day.meanTMT != null)
    return `Średnia temperatura: ${day.meanTMT.toFixed(2)}°C`;
  if (day.states.some(s => s.state === FurnaceState.DECOKING))
    return `Odkoksowanie`;
  if (day.states.some(s => s.state === FurnaceState.INOPERATIVE))
    return `Postój`;
  return 'Błąd czujników';
}

const renderTemperaturesPerHour = (day: IDay, classes) => {
  if (day.TMTHourly == null || day.TMTHourly.length === 0)
    return null
  const TMTHourly = day.TMTHourly.filter(t => t.hour >= 8 && t.hour<=16)
  if (TMTHourly.length === 0)
    return null;
  const lines: string[] = [];
  for (let item of TMTHourly) {
    lines.push(`godz. ${item.hour}: ${item.TMT.toFixed(2)}°C\n`);
  }
  return (
    <>
      <Typography
        variant="body2"
        classes={{root: classes.temperaturesPerHourHeader}}
      >
        Temperatury w ciągu dnia:
      </Typography>
      <div
        className={classes.temperaturesPerHourList}
      >
        {lines.map(l => (
          <Typography
            variant="body2"
          >
            {l}
          </Typography>
        ))}
      </div>
    </>
  )
}

function DaySummary(props: IProps) {
  const {classes, day} = props;
  const date = moment(day.date)
  const dateStr = date.format('D MMMM Y')
  return (
    <div
      className={classes.root}
    >
      <Typography variant="body1">
        {dateStr}
      </Typography>
      <Typography
        variant="body2"
        classes={{root: classes.stateName}}
      >
        {renderAlertMessage(day)}
        {prepareStateName(day)}
      </Typography>
      {renderTemperaturesPerHour(day, classes)}
    </div>
  );
}

export default withStyles(styles)(DaySummary)