import React from 'react';
import ReactDOM from 'react-dom';
import '@openfonts/ibm-plex-sans_latin-ext';
import '@openfonts/ibm-plex-sans-condensed_latin-ext';
import '@openfonts/red-hat-display_latin-ext';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {CustomTheme} from "./theme";
import {ThemeProvider} from "@material-ui/styles";
import {BrowserRouter} from "react-router-dom";
import * as moment from "moment";
import 'moment/locale/pl'
import {StoreProvider} from "./store/setup";
import SnackbarContainer from "./containers/SnackbarContainer";

const muiTheme = createMuiTheme(CustomTheme);
moment.locale('pl');

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider mock={false}>
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <SnackbarContainer>
            <App />
          </SnackbarContainer>
        </BrowserRouter>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
