import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.raymond,
    borderRadius: 24,
    padding: 32,
    boxShadow: '0 4px 8px 0 rgba(15, 15, 15, 0.08)'
  }
});

interface IProps extends WithStyles<typeof styles> {
  children: any
}

function Card(props: IProps) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(Card)