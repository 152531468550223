import {IApi, showDefaultApiErrorMessage} from "./api";
import {Messenger} from "./messenger";

export const createInvitationStore = (api: IApi, messenger: Messenger) => {
  return {
    async invite(email: string) {
      email = email.toLowerCase();
      const body = {
        email,
        invite: true
      };
      const {code, payload} = await api.post('reset/request', null, body);
      if (code === 200 && payload.ok) {
        messenger.showSuccess('Wysłano zaproszenie.');
        return;
      }
      else if (code === 410)
        messenger.showError('Nie udało się wysłać zaproszenia.');
      else if (code === 503)
        messenger.showError('Błąd po stronie serwera - związany z mailingiem albo bazą danych.');
      else
        showDefaultApiErrorMessage(code, payload, messenger);
    }
  }
};

export type TInvitationStore = ReturnType<typeof createInvitationStore>;