import * as React from "react";
import {observer} from "mobx-react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {useEffect} from "react";
import {useStore} from "../store/setup";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {IconButton, WithStyles} from "@material-ui/core";
import {ISubscriber} from "../store/messenger";
import Typography from "../components/Typography";

const AUTO_HIDE_DURATION = 6000;

enum MessageType {
  success,
  warning,
  error,
  info
}

const variantIcon = {
  [MessageType.success]: CheckCircleIcon,
  [MessageType.warning]: WarningIcon,
  [MessageType.error]: ErrorIcon,
  [MessageType.info]: InfoIcon,
} as Record<MessageType, any>;

const WrapperStyles = (theme: Theme) => createStyles({
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.raymond,
    paddingLeft: 8
  },
  iconButton: {
    padding: 4,
    color: theme.palette.raymond
  },
  root: {
    flexWrap: 'nowrap',
    paddingTop: 4,
    paddingBottom: 4
  },
  [MessageType.success]: {
    backgroundColor: theme.palette.patagonia700
  },
  [MessageType.warning]: {
    backgroundColor: theme.palette.harvey300
  },
  [MessageType.error]: {
    backgroundColor: theme.palette.dinosaur700
  },
  [MessageType.info]: {
    backgroundColor: theme.palette.louise700
  }
});

export interface WrapperProps extends WithStyles<typeof WrapperStyles>  {
  className?: string;
  message?: string;
  onClose?: (event: any, reason?: string) => void;
  type: MessageType;
}

const SnackbarContentWrapper = withStyles(WrapperStyles)((props: WrapperProps) => {
  const { classes, className, message, onClose, type, ...other } = props;
  const Icon = variantIcon[type];

  return (
    <SnackbarContent
      className={classes[type]}
      classes={{root: classes.root}}
      message={
        <span className={classes.messageContainer}>
          <Icon/>
          <Typography span variant="data2" className={classes.text}>{message}</Typography>
        </span>
      }
      action={[
        <IconButton
          key={'iconButton'}
          className={classes.iconButton}
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>,
      ]}
      {...other}
    />
  );
});

const styles = (theme: Theme) => createStyles({
  snackBarRoot: {
    top: 24
  }
});

interface IProps extends WithStyles<typeof styles> {
  children: any
}

const SnackbarContainer = observer((props: IProps)=>{
  const {classes, children} = props;
  const messenger = useStore().messenger;
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(MessageType.success);
  const [text, setText] = React.useState('');
  const [duration, setDuration] = React.useState<number | undefined>(undefined);
  const [subscriber] = React.useState({
    onSuccess(text: string, duration?: number) {
      handleShow(text, MessageType.success, duration);
    },
    onInformation(text: string, duration?: number) {
      handleShow(text, MessageType.info, duration);
    },
    onWarning(text: string, duration?: number) {
      handleShow(text, MessageType.warning, duration);
    },
    onError(text: string, duration?: number) {
      handleShow(text, MessageType.error, duration);
    },
    onHideAll() {
      setOpen(false);
    }
  } as ISubscriber);

  function handleShow(text: string, type: MessageType, duration?: number) {
    setDuration(duration);
    setType(type);
    setText(text);
    setOpen(true);
  }

  function handleHide(event: any, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  useEffect(() => {
    messenger.addSubscriber(subscriber);
    return () => {
      messenger.removeSubscriber(subscriber);
    }
  }, [messenger, subscriber]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          root: classes.snackBarRoot
        }}
        open={open}
        autoHideDuration={duration != null ? duration : AUTO_HIDE_DURATION}
        onClose={handleHide}
      >
        <SnackbarContentWrapper
          onClose={handleHide}
          type={type}
          message={text}
        />
      </Snackbar>
      {children}
    </div>
  );
});

export default withStyles(styles)(SnackbarContainer)