import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {IFurnace} from "../models/models";
import Card from "./Card";
import CalendarRow from "./CalendarRow";

const styles = (theme: Theme) => createStyles({
  cardRoot: {
    width: 625,
    [theme.breakpoints.up('md')]: {
      width: 940,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1210,
    },
  },
  row: {
    marginTop: 36,
    '&:first-child': {
      marginTop: 0
    }
  }
});

interface IProps extends WithStyles<typeof styles> {
  furnaces: IFurnace[]
}

function MultiCalendarCard(props: IProps) {
  const {classes, furnaces} = props;
  return (
    <Card classes={{root: classes.cardRoot}}>
      {
        furnaces.map(furnace => (
          <div className={classes.row} key={furnace.name}>
            <CalendarRow
              days={furnace.days}
              showName
              name={furnace.name}
            />
          </div>
        ))
      }
    </Card>
  );
}

export default withStyles(styles)(MultiCalendarCard)