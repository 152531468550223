import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import BaseForm from "./BaseForm";
import Typography from "./Typography";
import Button from "./Button";

const styles = (theme: Theme) => createStyles({
  baseFormRoot: {
    paddingBottom: 32,
  },
  button: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.impala500,
    cursor: 'pointer'
  }
});

interface IProps extends WithStyles<typeof styles> {
  header?: string,
  description?: string,
  buttonName?: string,
  onGoToMainPage?: () => void
}

function SummaryForm(props: IProps) {
  const {classes, header, description, buttonName, onGoToMainPage} = props;

  const handleButtonClick = () => {
    if (onGoToMainPage)
      onGoToMainPage();
  };

  return (
    <BaseForm
      classes={{
        root: classes.baseFormRoot
      }}
      header={header}
      description={description}
    >
      <Typography
        classes={{
          root: classes.button
        }}
        variant="button3"
        onClick={handleButtonClick}
      >
        {buttonName}
      </Typography>
    </BaseForm>
  );
}

export default withStyles(styles)(SummaryForm)