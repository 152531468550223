import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {ClickAwayListener, Fade, Grow, PopoverOrigin, Popper, WithStyles} from "@material-ui/core";
import {useState} from "react";
import _ from 'lodash';
import {Menu} from "./Menu";

const styles = (theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    display: 'inline-flex'
  },
  popper: {

  }
});

interface IProps extends WithStyles<typeof styles> {
  children: any
  content: any,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
}

function PopperOpener(props: IProps) {
  const {classes, children, content} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ uid ] = useState(() => _.uniqueId('popper-'));

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    console.log('click away')
    setAnchorEl(null);
  }

  return (
    <>
      <div
        onClick={handleClick}
        aria-controls={uid}
        aria-haspopup="true"
        className={classes.root}
      >
        {children}
      </div>
      <Popper
        className={classes.popper}
        id={uid}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={'bottom'}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            timeout={155}
            style={{ transformOrigin: '50% 0 0' }}
          >
            <div>
              <ClickAwayListener
                onClickAway={handleClickAway}
              >
                <div>
                  {content}
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
}

PopperOpener.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  } as PopoverOrigin,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  } as PopoverOrigin
};

export default withStyles(styles)(PopperOpener)