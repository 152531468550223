import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import BaseForm from "./BaseForm";
import {useState} from "react";
import Button from "./Button";
import Typography from "./Typography";
import lang from "../lang/lang";
import TextField from "./TextField";

const msg = lang.messages();

const styles = (theme: Theme) => createStyles({
  baseFormRoot: {
    paddingBottom: 32,
  },
  button: {
    marginTop: 20
  },
  emailLabel: {
    marginBottom: 4
  },
});

interface IProps extends WithStyles<typeof styles> {
  onResetClick?: (email: string) => void,
  onGoBack?: () => void,
}

function PasswordResetRequestForm(props: IProps) {
  const {classes, onResetClick, onGoBack} = props;
  const [email, setEmail] = useState('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleResetClick = (e) => {
    if (onResetClick)
      onResetClick(email);
    e.preventDefault();
  };

  const handleGoBack = () => {
    if (onGoBack)
      onGoBack();
  };

  return (
    <BaseForm
      classes={{
        root: classes.baseFormRoot
      }}
      description={msg.passwordResetDescription}
    >
      <form>
        <Typography
          classes={{
            root: classes.emailLabel
          }}
          variant="label1"
        >
          E-mail:
        </Typography>
        <TextField
          fullWidth
          onChange={handleEmailChange}
        />
        <Button
          fullWidth
          type="submit"
          classes={{
            root: classes.button
          }}
          onClick={handleResetClick}
        >
          {msg.passwordResetButton}
        </Button>
      </form>
    </BaseForm>
  );
}

export default withStyles(styles)(PasswordResetRequestForm)