import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import AppBar from "./AppBar";
import {IFurnace} from "../models/models";
import { Route, Switch } from "react-router-dom";
import ChartCard from "./ChartCard";
import MultiCalendarCard from "./MultiCalendarCard";
import moment from "moment";
import Card from "./Card";
import Typography from "./Typography";

const styles = (theme: Theme) => createStyles({
  content: {
    marginTop: 56,
    paddingBottom: 120,
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage:
      `linear-gradient(to bottom, 
      ${theme.palette.beyonder300},${theme.palette.beyonder500}
      )`
  },
  card: {
    marginTop: 48,
  },
  loadingCard: {
    marginTop: 48,
    width: 625,
    [theme.breakpoints.up('md')]: {
      width: 940,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1210,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

interface IProps extends WithStyles<typeof styles> {
  userName: string,
  finalDate: Date,
  maxDate: Date,
  minDate: Date,
  currentDate: Date,
  dayCount: number,
  furnaces: IFurnace[],
  onLogOut?: ()=>void,
  onOpenSettings?: ()=>void,
  onSetFinalDate?: (finalDate: Date)=>void,
}

function MainScreen(props: IProps) {
  const {classes, userName, finalDate, maxDate, minDate, currentDate, dayCount, furnaces, onLogOut,
    onOpenSettings, onSetFinalDate} = props;

  const startDate = moment(finalDate).subtract(dayCount, 'd').toDate();

  const furnacesWindow = furnaces.map(furnace => ({
    ...furnace,
    days: furnace.days.filter(
      day => moment(day.date).isBetween(
        startDate,
        finalDate,
        'd',
        '[]'
      )
    )
  } as IFurnace));

  const renderContent = () => {
    if (furnaces.length >0) return (
      <Switch>
        <Route exact path="/">
          <ChartCard
            classes={{cardRoot: classes.card}}
            furnaces={furnacesWindow}
            currentDate={currentDate}
          />
          <MultiCalendarCard
            classes={{cardRoot: classes.card}}
            furnaces={furnacesWindow}
          />
        </Route>
      </Switch>
    )
    return (
      <Card
        classes={{root: classes.loadingCard}}
      >
        <Typography variant="body2">
          Wczytywanie danych...
        </Typography>
      </Card>
    )
  }

  return (
    <div>
      <AppBar
        userName={userName}
        finalDate={finalDate}
        maxDate={maxDate}
        minDate={minDate}
        dayCount={dayCount}
        onLogOut={onLogOut}
        onOpenSettings={onOpenSettings}
        onSetFinalDate={onSetFinalDate}
      />
      <div className={classes.content}>
        {renderContent()}
      </div>
    </div>
  );
}

export default withStyles(styles)(MainScreen)