const developmentUrl = 'https://datapanel.anzonia.pl/';

// custom error codes:

export const FETCH_ERROR = 1000;

export interface IResponse {
  code: number,
  payload?: any
}

export function buildUrl() {
  const hostname = window.location.hostname;
  if (hostname === 'localhost')
    return developmentUrl;
  if (hostname.endsWith('surge.sh'))
    return developmentUrl;
  return window.location.protocol + '//' + window.location.hostname + '/';
}

export function buildApiUrl() {
  return buildUrl() + 'api/'
}

export async function post(
  method: string, id: number | string | null, body: any, bodyToJson: boolean = true
): Promise<IResponse>  {
  const url = buildApiUrl() + method + (id!=null ? `/${id}` : '');
  const params = {
    credentials: "include" as const,
    method: 'POST',
    body: bodyToJson ? JSON.stringify(body) : body
  } as any;
  if (bodyToJson)
    params.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  if (bodyToJson) {
    if ('password' in body)
      console.log(`Body of request to ${url} with method ${params.method} contains a password. Logging omitted.`);
    else
      console.log(`Body of request to ${url} with method ${params.method}: ${JSON.stringify(body, null, 2)}`);
  }
  return await request(url, params);
}

export async function get(method: string, id?: number | string | null): Promise<IResponse>  {
  const url = buildApiUrl() + method + (id!=null ? `/${id}` : '');
  const params = {
    credentials: "include" as const,
    headers: {
      'Accept': 'application/json'
    },
    method: 'GET'
  };
  return await request(url, params);
}

export async function del(method: string, id?: number | string | null): Promise<IResponse>  {
  const url = buildApiUrl() + method + (id!=null ? `/${id}` : '');
  const params = {
    credentials: "include" as const,
    headers: {
      'Accept': 'application/json'
    },
    method: 'DELETE'
  };
  return await request(url, params);
}

export async function request(url: string, params: any): Promise<IResponse> {
  console.log(`Fetching from ${url} with method ${params.method}.`);
  let response;
  try {
    response = await fetch(url, params);
  } catch (e) {
    console.log(`Error when fetching from ${url} with method ${params.method}: "${e}".`);
    return {
      code: FETCH_ERROR
    }
  }
  console.log(`Status of response from ${url} with method ${params.method}: ${response.status}`);
  if (response.status !== 200) {
    return {
      code: response.status
    }
  }
  const responseBody = await response.json();
  console.log(`Body of response from ${url} with method ${params.method}: ${JSON.stringify(responseBody,null, 2)}`);
  return {
    code: 200,
    payload: responseBody
  }
}