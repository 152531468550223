import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {useLocalStore} from "mobx-react-lite";
import {createPasswordResetRequestStore, State} from "../store/passwordResetRequestStore";
import {useStore} from "../store/setup";
import {observer} from "mobx-react";
import {useHistory, useLocation} from "react-router";
import PasswordResetRequestForm from "../components/PasswordResetRequestForm";
import SummaryForm from "../components/SummaryForm";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
}

const PasswordResetRequest = observer((props: IProps) => {
  const {classes} = props;
  const store = useStore();
  const state = useLocalStore(()=>({
    resetRequest: createPasswordResetRequestStore(store.api, store.messenger),
  }));
  const history = useHistory();

  const handleGoToMainPage = () => {
    history.push(`/`);
  };

  const handleResetClick = (email: string) => {
    state.resetRequest.submitEmail(email);
  };

  if (state.resetRequest.state === State.init)
    return (
      <PasswordResetRequestForm
        onResetClick={handleResetClick}
        onGoBack={handleGoToMainPage}
      />
    );

  if (state.resetRequest.state === State.emailSent)
    return (
      <SummaryForm
        description={'Instrukcja do zresetowania hasła została wysłana na podany adres e-mail.'}
        buttonName={'Powrót do logowania'}
        onGoToMainPage={handleGoToMainPage}
      />
    );

  if (state.resetRequest.state === State.error)
    return (
      <SummaryForm
        description={'Reset hasła nie powiódł się.'}
        buttonName={'Powrót do logowania'}
        onGoToMainPage={handleGoToMainPage}
      />
    );
  return null;
});

export default withStyles(styles)(PasswordResetRequest)