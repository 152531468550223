import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import MUIButton from "@material-ui/core/Button";
import classNames from 'classnames';
import {MouseEventHandler} from "react";

const styles = (theme: Theme) => createStyles({
  root: {
    ...theme.text.button2,
    backgroundColor: theme.palette.rockslide500,
    color: theme.palette.raymond,
    textTransform: 'unset',
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.rockslide400,
    },
  },
  text: {
    paddingLeft: 16,
    paddingRight: 16
  },
  s: {},
  m: {},
  l: {},
  xl: {}
});

interface IProps extends WithStyles<typeof styles> {
  fullWidth?: boolean,
  type?: any,
  onClick?: MouseEventHandler,
  onMouseDown?: MouseEventHandler,
  children?: React.ReactNode
}

function Button(props: IProps) {
  const {classes, fullWidth, type, onClick, onMouseDown, children} = props;
  return (
    <MUIButton
      fullWidth={fullWidth}
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      classes={{
        root: classNames(
          classes.root,
        ),
        text: classes.text
      }}
    >
      {children}
    </MUIButton>
  );
}

export default withStyles(styles)(Button)