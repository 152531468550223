import {Theme as T} from "@material-ui/core/styles/createMuiTheme";
import {CSSProperties} from "react";
import * as React from "react";

export const single_coil_threshold = 1100 + 25;
export const high_threshold = 1100 + 25;
export const medium_threshold = 1100 + 25;

export const simulation_length = 10; // in days

export const palette = {
  raymond: '#ffffff',
  rockslide050: '#e5e5e5',
  rockslide100: '#bfbfbf',
  rockslide200: '#949494',
  rockslide300: '#696969',
  rockslide400: '#494949',
  rockslide500: '#292929',
  rockslide600: '#242424',
  rockslide700: '#1f1f1f',
  rockslide800: '#191919',
  rockslide900: '#0f0f0f',
  impala050: '#ebebff',
  impala200: '#abacfd',
  impala100: '#cccdfe',
  impala300: '#898afc',
  impala400: '#6f71fc',
  impala500: '#5658fb',
  impala600: '#4f50fa',
  impala700: '#4547fa',
  impala900: '#2b2df8',
  impala800: '#3c3df9',
  triton050: '#e1f5f3',
  triton100: '#b5e6e1',
  triton200: '#84d6cd',
  triton300: '#53c5b8',
  triton500: '#09ac9a',
  triton400: '#2eb8a9',
  triton600: '#08a592',
  triton800: '#05927e',
  triton700: '#069b88',
  triton900: '#02826c',
  beyonder050: '#fcfcfd',
  beyonder100: '#f6f7fa',
  beyonder200: '#ebedf3',
  beyonder300: '#e2e5ee',
  beyonder400: '#d1d5e0',
  beyonder500: '#c9cddb',
  beyonder600: '#c3c8d7',
  beyonder700: '#bcc1d2',
  beyonder800: '#b5bacd',
  beyonder900: '#a9aec4',
  dinosaur050: '#fff2f5',
  dinosaur100: '#fedce4',
  dinosaur200: '#fcc7d2',
  dinosaur300: '#f893a3',
  dinosaur400: '#f1687a',
  dinosaur500: '#e84858',
  dinosaur600: '#dc2d46',
  dinosaur700: '#cc1f40',
  dinosaur800: '#b7133a',
  dinosaur900: '#87002d',
  louise500: '#0cc1ea',
  louise050: '#e2f8fc',
  louise600: '#0abbe7',
  louise300: '#55d4f0',
  louise100: '#b6ecf9',
  louise200: '#86e0f5',
  louise400: '#30caed',
  louise700: '#08b3e4',
  louise800: '#06abe1',
  louise900: '#039edb',
  patagonia050: '#f1fcd9',
  patagonia100: '#e9f9c1',
  patagonia200: '#d9f28d',
  patagonia300: '#c8e861',
  patagonia400: '#b6da41',
  patagonia500: '#a1c82a',
  patagonia600: '#80b919',
  patagonia700: '#5fa80f',
  patagonia800: '#419707',
  patagonia900: '#268700',
  harvey050: '#fffbe4',
  harvey100: '#fff9d6',
  harvey200: '#fff5bb',
  harvey300: '#f2e179',
  harvey400: '#d8c455',
  harvey500: '#b7a759',
  harvey600: '#948957',
  harvey700: '#817646',
  harvey800: '#776c3c',
  harvey900: '#65592c',
  melissa500: '#f902f9',
};

export const CustomTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    ...palette,
    states: {
      lightNaphtha: palette.harvey200,
      mediumNaphtha: palette.louise050,
      heavyNaphtha: palette.harvey600,
      gas: palette.melissa500,
      hydrocrackingResidues: palette.beyonder500,
      heavyDiesel: palette.dinosaur900,
      hotReserve: palette.triton500,
      TLECleaning: palette.dinosaur100,
      decoking: palette.rockslide900,
      noDataBackground: palette.raymond,
      noDataBorder: palette.beyonder300,
      error: 'linear-gradient(213deg, #FF9933 0%, #FFD713 100%)',
      inoperative: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAO0lEQVQYlY3KuRUAIAhEwS3PEuw/sotvJM8DkIlHFIzWUSUBeVwpjXsK453c6KUnRumIWbL4SwCqJIAJdrR/7lYD0UkAAAAASUVORK5CYII="
    },
    background: {
      default: palette.raymond //this is applied to body.backgroundColor by CssBaseline component
    }
  },
  text: {
    h1Headline: {
      fontFamily: 'Red Hat Display',
      fontSize: 36,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '48px',
      letterSpacing: 1.6
    },
    h2Headline: {
      fontFamily: 'Red Hat Display',
      fontSize: 32,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '44px',
      letterSpacing: 1.4
    },
    h3Headline: {
      fontFamily: 'Red Hat Display',
      fontSize: 24,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '36px',
      letterSpacing: 0.8
    },
    h4Headline: {
      fontFamily: 'Red Hat Display',
      fontSize: 20,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '30px',
      letterSpacing: 0.64
    },
    button1HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 18,
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.6
    },
    subtitle1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 18,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.58
    },
    input1HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 18,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.4
    },
    button1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 18,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.6
    },
    input1LowEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 18,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.4,
      color: palette.rockslide300,
    },
    subtitle2: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.58
    },
    body1MediumEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.48
    },
    body1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 16,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.48
    },
    button2HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.6
    },
    productName: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 1,
      color: '#313131'
    },
    subtitle3: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.58
    },
    input2HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.4
    },
    input2: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.48
    },
    button2: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.6
    },
    body2MediumEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.56
    },
    input2LowEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.48
    },
    body2: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: 0.56
    },
    button3HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.6
    },
    tag1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.62
    },
    subtitle4: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.58
    },
    label1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.64
    },
    input3HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.4
    },
    button3: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.6
    },
    body3MediumEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.56
    },
    input3LowEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.4,
      color: palette.rockslide300
    },
    body3: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.56
    },
    button4HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 11,
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.6
    },
    input4HighEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 11,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.4
    },
    button4: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 11,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.6
    },
    data1: {
      fontFamily: 'IBM Plex Sans Condensed',
      fontSize: 11,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: -0.48,
      textAlign: 'center'
    },
    input4LowEmphasis: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 11,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.4,
      color: palette.rockslide300
    },
    tag2: {
      fontFamily: 'IBM Plex Sans Condensed',
      fontSize: 8,
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.38
    },
    label2: {
      fontFamily: 'IBM Plex Sans',
      fontSize: 10,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.64
    },
    tag3: {
      fontFamily: 'IBM Plex Sans Condensed',
      fontSize: 8,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0.64
    }
  } as Record<string, Record<string, CSSProperties>>, //let Typescript watch CSS
};

export type Theme = (typeof CustomTheme) & T;