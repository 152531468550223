import * as React from "react";
import {CustomTheme, simulation_length, Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {Feedstock, FurnaceState, IFurnace} from "../models/models";
import {randomInt} from "../utils/math";
import {randomEnum} from "../utils/typescript";
import moment from "moment";
import MainScreen from "../components/MainScreen";
import {useStore} from "../store/setup";
import {observer} from "mobx-react";
import {daysToMs} from "../utils/etc";
import useWindowDimensions from "../utils/hooks";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
}

const Panel = observer((props: IProps) => {
  const {classes} = props;
  const [finalDate, setFinalDate] = React.useState(new Date(Date.now() + daysToMs(simulation_length-1)));
  const {height, width} = useWindowDimensions();
  const store = useStore();
  const furnaces = store.furnaceStore.furnaces;

  const handleLogout = () => {
    store.sessionStore.logout();
  }

  let userName = '';
  if (store.sessionStore.user != null) {
    userName = store.sessionStore.user.email;
  }

  let dayCount = 15;
  if (width >= CustomTheme.breakpoints.values.md)
    dayCount = 24;
  if (width >= CustomTheme.breakpoints.values.lg)
    dayCount = 31;

  return (
    <MainScreen
      userName={userName}
      finalDate={finalDate}
      maxDate={new Date(Date.now() + daysToMs(simulation_length-1))}
      minDate={new Date(2015, 0, 1)}
      currentDate={new Date(Date.now())}
      dayCount={dayCount}
      onSetFinalDate={(date)=>{setFinalDate(date)}}
      onLogOut={handleLogout}
      furnaces={furnaces}
    />
  );
});

export default withStyles(styles)(Panel)