import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme: Theme) => createStyles({
  root: {
    color: theme.palette.impala500,
  }
});

interface IProps extends WithStyles<typeof styles> {
}

function DropIcon(props: IProps) {
  const {classes} = props;
  return (
    <ArrowDropDownIcon className={classes.root}/>
  );
}

export default withStyles(styles)(DropIcon)