import React from 'react';
import './App.css';
import Main from "./containers/Main";

function App() {
  const [finalDate, setFinalDate] = React.useState(new Date(2020, 5, 20));
  return (
    <Main/>
  );
}

export default App;
