import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import LoginForm from "../components/LoginForm";
import {useStore} from "../store/setup";
import { useHistory } from "react-router-dom";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
}

function Login(props: IProps) {
  const {classes} = props;
  const store = useStore();
  const history = useHistory();

  const handleLoginClick = async (email: string, password: string) => {
    await store.sessionStore.auth(email, password);
    if (store.sessionStore.isAuthenticated) {
      await store.furnaceStore.updateFurnaces();

      await store.userStore.updateUsers();
      // await store.userStore.deleteUser(
      //   { id: '0sf0gjkjgd0gdfgdfgd', email:''}
      // );
      //await store.userStore.createUser('email@email.com');
      //await store.invitationStore.invite('email@email.com');
    }
  };

  const handleResetClick = () => {
    history.push('/password-reset')
  };

  return (
    <LoginForm
      onLoginClick={handleLoginClick}
      onResetPassword={handleResetClick}
    />
  );
}

export default withStyles(styles)(Login)