import {IApi, showDefaultApiErrorMessage} from "./api";
import {Messenger} from "./messenger";
import {IUser, UserAccessType} from "../models/models";

export const createUsersStore = (api: IApi, messenger: Messenger) => {
  return {
    users: [] as IUser[],
    async updateUsers() {
      const {code, payload} = await api.get('user');
      if (code === 200 && payload.ok) {
        this.users = payload.data.filter(
          (user: IUser) => !user.email.includes('anzonia.pl')
        );
      }
    },
    async createUser(email: string) {
      const {code, payload} = await api.post(
        'user',
        null,
        {
          email,
          access_type: UserAccessType.Authorized
        } as Partial<IUser>
      );
      if (code === 200 && payload.ok) {
        await this.updateUsers();
        return payload.id;
      }
      showDefaultApiErrorMessage(code, payload, messenger,
        'Błąd podczas dodawania użytkownika.')
    },
    async saveUser(user: IUser) {
      const body = {
        ...user,
        id: undefined
      } as Partial<IUser>;
      const {code, payload} = await api.post('user', user.id, body);
      if (code === 200 && payload.ok) {
        await this.updateUsers();
        return;
      }
      showDefaultApiErrorMessage(code, payload, messenger,
        'Błąd podczas zmiany danych użytkownika.')
    },
    async deleteUser(user: IUser) {
      const {code, payload} = await api.del('user', user.id);
      if (code === 200 && payload.ok) {
        messenger.showSuccess('Usunięto użytkownika.');
        await this.updateUsers();
        return;
      }
      showDefaultApiErrorMessage(code, payload, messenger,
          'Błąd podczas usuwania użytkownika.')
    },
  }
};

export type TUsersStore = ReturnType<typeof createUsersStore>;