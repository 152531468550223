import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {PopoverOrigin, WithStyles} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import {useState} from "react";
import _ from 'lodash';
import {Menu} from "./Menu";

const styles = (theme: Theme) => createStyles({
  popover: {
    pointerEvents: 'none',
    marginBottom: 160,

  },
  paper: {
    padding: 12,
    marginBottom: 240,
    backgroundColor: theme.palette.raymond,
    borderRadius: 10,
    boxShadow: '0 4px 8px 0 rgba(15, 15, 15, 0.08)',
  },
});

interface IProps extends WithStyles<typeof styles> {
  children: any,
  content: any,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
}

function PopoverOpener(props: IProps) {
  const {classes, children, content, anchorOrigin, transformOrigin} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [uid] = useState(() => _.uniqueId('popover-'));

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-owns={open ? uid : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </div>
      <Popover
        id={uid}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {content}
      </Popover>
    </div>
  )
}

PopoverOpener.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  } as PopoverOrigin,
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  } as PopoverOrigin
};

export default withStyles(styles)(PopoverOpener)