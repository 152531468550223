export function objectsHaveSameKeys(...objects) {
  const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
  const union = new Set(allKeys);
  return objects.every(object => union.size === Object.keys(object).length);
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function daysToMs(days) {
  return 1000*60*60*24*days;
}