import {IApi} from "./api";
import {Messenger} from "./messenger";

export enum State {
  init,
  error,
  emailSent,
}

export const createPasswordResetRequestStore = (api: IApi, messenger: Messenger) => {
  return {
    state: State.init,
    errorMessage: '',
    email: '',
    async submitEmail(email: string) {
      email = email.toLowerCase();
      this.email = email;
      const body = {
        email,
        invite: false
      };
      const {code, payload} = await api.post('reset/request', null, body);
      if (code === 200 && payload.ok) {
        this.state = State.emailSent;
        return;
      }
      else if (code === 410)
        this.errorMessage = 'Cannot request a new password.';
      else if (code === 503)
        this.errorMessage = 'Serverside error - related to mailing or database support.';
      else
        this.errorMessage = 'Unexpected error.';
      this.state = State.error;
    },
    resetFlow() {
      this.state = State.init;
      this.errorMessage = '';
      this.email = '';
    }
  }
};

export type TPasswordResetRequestStore = ReturnType<typeof createPasswordResetRequestStore>;