import * as React from "react";
import {Theme} from "../theme";
import {withStyles} from "@material-ui/styles";
import {MenuProps} from "@material-ui/core";
import MUMenu from "@material-ui/core/Menu";
import MUMenuItem from "@material-ui/core/MenuItem";

export const MenuItem = withStyles((theme: Theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.raymond,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.raymond,
      },
    },
    ...theme.text.body2
  },
  gutters: {
    paddingLeft: 24,
    paddingRight: 24
  }
}))(MUMenuItem);

const Menu = withStyles({
  paper: {
    borderRadius: 16,
    boxShadow: '0 16px 32px 0 rgba(15, 15, 15, 0.12)',
  },
  list: {
    padding: '10px 0'
  }
})((props: MenuProps) => (
  <MUMenu
    elevation={0}
    getContentAnchorEl={null}
    {...props}
  />
));

Menu.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  }
};

export {Menu};

