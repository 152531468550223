import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  root: (props: any) => ({
    ...theme.text[props.variant]
  })
});

interface IProps extends WithStyles<typeof styles> {
  variant: any,
  children: any,
  span?: boolean
}

function Typography(props: IProps) {
  const {classes, span, ...others} = props;

  if (span) return (
    <span className={classes.root} {...others}>
      {props.children}
    </span>
  );
  return (
    <div className={classes.root} {...others}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(Typography)