import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {IDay} from "../models/models";
import CalendarItem from "./CalendarItem";
import Typography from "./Typography";

const styles = (theme: Theme) => createStyles({
  root: {
  },
  calendarRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  name: {
    display: (props: any) => props.showName ? 'block' : 'hidden',
    color: theme.palette.impala500,
    marginBottom: 4
  }
});

interface IProps extends WithStyles<typeof styles> {
  days: IDay[],
  showName?: boolean,
  name?: string
}

function CalendarRow(props: IProps) {
  const {classes, days, name} = props;
  return (
    <div className={classes.root}>
      <div className={classes.name}>
        <Typography variant="body2MediumEmphasis">{name}</Typography>
      </div>
      <div className={classes.calendarRoot}>
        {days.map((day)=> <CalendarItem day={day}/>)}
      </div>
    </div>
  );
}

export default withStyles(styles)(CalendarRow)