import * as React from "react";
import {high_threshold, medium_threshold, Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {Feedstock, FurnaceState, IDay} from "../models/models";
import classNames from 'classnames';
import Typography from "./Typography";
import PopoverOpener from "./PopoverOpener";
import DaySummary from "./DaySummary";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = (theme: Theme) => createStyles({
  root: {},
  forecast: {},
  decoking: {},
  low: {},
  medium: {},
  high: {},
  inoperative: {},
  missing: {},
  error: {},
  lightNaphtha: {},
  mediumNaphtha: {},
  heavyNaphtha: {},
  gas: {},
  hydrocrackingResidues: {},
  heavyDiesel: {},
  clear: {},
  alert: {},
  capsule: {
    width: 34,
    height: 68,
    overflow: 'hidden',
    '$forecast &': {
      borderRadius: 10
    },
  },
  capsuleInfo: {
    width: 34,
    height: 68,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    alignItems: 'center',
    position: 'absolute',
    boxSizing: 'border-box',
    '&$missing': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.states.noDataBorder
    },
    '$forecast &$missing': {
      borderRadius: 10
    }
  },
  capsuleBackground: {
    width: 34,
    height: 68,
    display: 'flex',
    flexDirection: 'row',
  },
  capsuleBackgroundItem: {
    height: 68,
    '&$low': {
      backgroundColor: theme.palette.impala100
    },
    '&$medium': {
      backgroundColor: theme.palette.impala300
    },
    '&$high': {
      backgroundColor: theme.palette.impala500
    },
    '&$decoking': {
      backgroundColor: theme.palette.states.decoking
    },
    '&$inoperative': {
      'background': `url(${theme.palette.states.inoperative})`
    },
    '&$missing': {
      backgroundColor: theme.palette.states.noDataBackground,
    },
    '&$error': {
      backgroundImage: theme.palette.states.error
    }
  },
  iconLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 18,
    marginTop: 2,
    visibility: 'hidden',
    '$alert &': {
      visibility: 'visible',
    },
    zIndex: 10
  },
  temperatureLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 18,
    borderRadius: 6,
    marginTop: 4,
    backgroundColor: theme.palette.raymond,
    '$decoking &': {
      backgroundColor: theme.palette.rockslide400,
      color: theme.palette.raymond
    },
    '$clear &': {
      visibility: 'hidden'
    },
    zIndex: 10
  },
  daysLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 14,
    borderRadius: 6,
    marginTop: 8,
    backgroundColor: theme.palette.raymond,
    '$decoking &, $clear &': {
      visibility: 'hidden'
    },
    zIndex: 10,
  },
  alertIcon: {
    color: 'black',
  },
  feed: {
    marginTop: 4,
    display: 'flex',
    width: 34,
  },
  feedLeft: {
    height: 8,
    flexGrow: 1,
    '$forecast &': {
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    },
  },
  feedRight: {
    height: 8,
    flexGrow: 1,
    '$forecast &': {
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
  },
  feedBackground: {
    '&$lightNaphtha': {
      backgroundColor: theme.palette.states.lightNaphtha
    },
    '&$mediumNaphtha': {
      backgroundColor: theme.palette.states.mediumNaphtha
    },
    '&$heavyNaphtha': {
      backgroundColor: theme.palette.states.heavyNaphtha
    },
    '&$gas': {
      backgroundColor: theme.palette.states.gas
    },
    '&$hydrocrackingResidues': {
      backgroundColor: theme.palette.states.hydrocrackingResidues
    },
    '&$heavyDiesel': {
      backgroundColor: theme.palette.states.heavyDiesel
    },
    '&$clear': {
      visibility: 'hidden'
    },
  },
  popover: {
    transform: 'translateY(-16px) !important'
  }
});

interface IProps extends WithStyles<typeof styles> {
  day: IDay
}

enum LeadingStyle {
  UNKNOWN,
  OPERATIVE,
  DECOKING,
  DECOKING_DANGER,
  CLEAR
}

function CalendarItem(props: IProps) {
  const {classes, day} = props;

  let leadingStyle = LeadingStyle.UNKNOWN;
  for (let state of day.states)
    if (state.state === FurnaceState.OPERATIVE)
      leadingStyle = LeadingStyle.OPERATIVE;
  if (day.forecast === true)
    leadingStyle = LeadingStyle.OPERATIVE;
  if (day.forecast && day.missing)
    leadingStyle = LeadingStyle.CLEAR;
  if (leadingStyle === LeadingStyle.UNKNOWN)
    for (let state of day.states)
      if (state.state === FurnaceState.DECOKING)
        leadingStyle = LeadingStyle.DECOKING;
  if (leadingStyle === LeadingStyle.UNKNOWN)
    leadingStyle = LeadingStyle.CLEAR;

  let meanTemperature;
  if (day.meanTMT!=null)
    meanTemperature = day.meanTMT;
  else
    meanTemperature = 0;
  const temperatureStr = Math.round(meanTemperature).toString() + '°';

  const alert = (day.outliers != null && day.outliers.length > 0);

  const getFeedClasses = (feedstock: Feedstock) => {
    return {
      [classes.feedBackground]: true,
      [classes.lightNaphtha]: feedstock === Feedstock.LIGHT_NAPHTHA,
      [classes.mediumNaphtha]: feedstock === Feedstock.MEDIUM_NAPHTHA,
      [classes.heavyNaphtha]: feedstock === Feedstock.HEAVY_NAPHTHA,
      [classes.gas]: feedstock === Feedstock.GAS,
      [classes.hydrocrackingResidues]:
        feedstock === Feedstock.HYDROCRACKING_RESIDUES,
      [classes.heavyDiesel]: feedstock === Feedstock.HEAVY_DIESEL,
      [classes.hydrocrackingResidues]:
        feedstock === Feedstock.GROUP__DIESEL_OR_HCR,
      [classes.clear]: feedstock === Feedstock.NONE
    }
  }

  const drawFeed = () => {
    const states = day.states;
    let feedLeft = Feedstock.NONE;
    let feedRight = Feedstock.NONE;
    if (states.length === 1) {
      if (states[0].state === FurnaceState.OPERATIVE) {
        feedLeft = states[0].feedstock;
        feedRight = states[0].feedstock;
      }
    }
    else if (states.length >1) {
      if (states[0].state === FurnaceState.OPERATIVE)
        feedLeft = states[0].feedstock;
      if (states[states.length - 1].state === FurnaceState.OPERATIVE)
        feedRight = states[states.length - 1].feedstock;
    }
    return (
      <>
        <div
          className={
            classNames(classes.feedLeft, getFeedClasses(feedLeft))
          }
        />
        <div
          className={
            classNames(classes.feedRight, getFeedClasses(feedRight))
          }
        />
      </>
    )
  }

  const getBackground = (state: FurnaceState, tmt?: number) => {
    return (
      <div
        style={{flexGrow: 1}}
        className={classNames(
          classes.capsuleBackgroundItem,
          {
            [classes.decoking]: state === FurnaceState.DECOKING,
            [classes.inoperative]: state === FurnaceState.INOPERATIVE,
            [classes.low]:
              (state === FurnaceState.FORECAST || state === FurnaceState.OPERATIVE)
                && tmt != null && tmt < medium_threshold,
            [classes.medium]:
              (state === FurnaceState.FORECAST || state === FurnaceState.OPERATIVE)
                && tmt != null && tmt >= medium_threshold,
            [classes.high]:
              (state === FurnaceState.FORECAST || state === FurnaceState.OPERATIVE)
                && tmt != null && tmt >= high_threshold,
            [classes.missing]:
              state === FurnaceState.MISSING,
            [classes.error]:
              state === FurnaceState.ERROR
          }
        )}
      />
    )
  }

  const calculateBackgrounds = () => {
    if (day.missing) {
      return getBackground(FurnaceState.MISSING);
    }
    if (day.forecast) {
      return getBackground(FurnaceState.FORECAST, meanTemperature);
    }
    if (day.states.length === 1) {
      const state = day.states[0]
      return getBackground(state.state, meanTemperature);
    }
    if (day.states.length > 1) {
      const firstState = day.states[0];
      const lastState = day.states[day.states.length-1];
      return [
        getBackground(firstState.state, meanTemperature),
        getBackground(lastState.state, meanTemperature),
      ]
    }
    return getBackground(FurnaceState.ERROR);
  }

  return (
    <PopoverOpener
      classes={{ paper: classes.popover }}
      content={(
        <DaySummary
          day={day}
        />
      )}
    >
      <div
        className={
          classNames(
            classes.root,
            {[classes.forecast]: day.forecast},
            {[classes.low]: meanTemperature < medium_threshold},
            {[classes.medium]: meanTemperature >= medium_threshold},
            {[classes.high]: meanTemperature >= high_threshold},
            {[classes.alert]: alert}
          )
        }
      >
        <div
          className={classes.capsule}
        >
          <div
             className={
               classNames(
                 classes.capsuleInfo,
                 {[classes.clear]: leadingStyle === LeadingStyle.CLEAR},
                 {[classes.decoking]: leadingStyle === LeadingStyle.DECOKING},
                 {[classes.missing]: day.missing === true}
               )
             }
          >
            <div className={classes.iconLabel}>
              <ErrorOutlineIcon
                className={classes.alertIcon}
                fontSize={'small'}
              />
            </div>
            <div className={classes.temperatureLabel}>
              <Typography variant="data1">
                {leadingStyle === LeadingStyle.DECOKING ? 'O' : temperatureStr}
              </Typography>
            </div>
            <div className={classes.daysLabel}>
              <Typography variant="tag2">{day.workingTime}</Typography>
            </div>
          </div>
          <div className={classes.capsuleBackground}>
            {calculateBackgrounds()}
          </div>
        </div>
        <div
          className={classes.feed}
        >
          {drawFeed()}
        </div>
      </div>
    </PopoverOpener>
  );
}

export default withStyles(styles)(CalendarItem)